<template>
  <Modal
    :show="isShow"
    title="Edit user"
    :errors="errors"
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ data }}</pre> -->
    <CRow>
      <CCol sm="12">
        <CInput v-model="form.email" label="Email" placeholder="Email"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.balance" label="Balance" placeholder="Balance"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.total_balance" label="Deposits" placeholder="Deposits"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.loyal_points" label="Loyal" placeholder="Loyal"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.country" label="Country" placeholder="Country"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.source" label="Source" placeholder="Source"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CInput v-model="form.source_url" label="Source url" placeholder="Source url"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <AppSlect v-model="form.status" :options="statusOptions" label="Status"/>
        <!-- <CSelect v-model="form.status" label="Status" :options="gatewayOptions"/> -->
      </CCol>
    </CRow>
  </Modal>
</template>

<script>
// import Modal from "@/components/root/Modal";
import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "UserModalsAddBalance",

  mixins: [MixinsScreenModal],

  data: () => ({
    form: {
      email: null,
      balance: null,
      total_balance: null,
      loyal_points: null,
      country: null,
      source: null,
      source_url: null,
      status: null
    },

    statusOptions: [
      { value: 0, label: "NOT_VERIFIED" },
      { value: 1, label: "ACTIVE" },
      { value: 2, label: "BANNED" }
    ]
  })
};
</script>


<style lang="scss" scoped>
</style>