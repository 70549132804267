<template>
  <Modal
    :show="isShow"
    title="Add balance"
    :errors="errors"
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <CRow>
      <CCol sm="12">
        <CInput v-model="form.amount" label="Balance" placeholder="Balance"/>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput v-model="form.bonus" label="Bonus" placeholder="Bonus"/>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <AppSlect v-model="form.gateway" :options="gatewayOptions" label="Gateway"/>
        <!-- <CSelect value="form.gateway" label="Gateway" :options="gatewayOptions"/> -->
      </CCol>
    </CRow>
  </Modal>
</template>

<script>
import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "UserModalsAddBalance",

  mixins: [MixinsScreenModal],

  props: {
    userId: {
      type: [Number, String]
    }
  },

  data: () => ({
    form: {
      amount: null,
      bonus: null,
      gateway: "",
      user: null
    },

    gatewayOptions: [
      {
        value: "manual",
        label: "manual"
      },
      {
        value: "fee",
        label: "fee"
      },
      {
        value: "bonus",
        label: "bonus"
      },
      {
        value: "refund",
        label: "refund"
      },
      {
        value: "paypal",
        label: "paypal"
      },
      {
        value: "crypto",
        label: "crypto"
      }
    ],

    fetchMethods: "post"
  }),

  watch: {
    isShow(next) {
      this.form.user = this.userId;
      this.form.gateway = "manual";
    }
  }
};
</script>