<template>
  <div class="user">
    <!-- <pre>{{data}}</pre> -->
    <CRow>
      <CCol col="12" md="9" lg="9" xl="6">
        <CCard>
          <!-- <CCardHeader class="user__header header">
            <span class="header__title">User id: {{ $route.params.id }}</span>

            <CButton
              class="px-2 header__button"
              size="sm"
              color="danger"
              @click="setActiveModal('setNewPassword')"
            >
              <span>Set Password</span>
            </CButton>

            <CButton
              class="px-2 header__button"
              size="sm"
              color="success"
              @click="setActiveModal('addBalance')"
            >
              <span>Add Balance</span>
            </CButton>

            <CButton
              class="px-2 header__button"
              size="sm"
              color="warning"
              @click="handlerVerificated"
            >
              <span>Send verification</span>
            </CButton>

            <CButton
              class="px-2 header__button"
              size="sm"
              color="info"
              @click="setActiveModal('editUser')"
            >
              <span>Edit User</span>
            </CButton>
          </CCardHeader>-->

          <CCardBody class="user__body">
            <CRow class="user__row" v-for="( value, name ) in fields" :key="name">
              <CCol class="user__label" col="5" lg="4">{{ fields[name] }}:</CCol>
              <CCol class="user__value" col="7" lg="8">{{ getValue(data[name], name) }}</CCol>
              <!-- <pre>{{data}}</pre> -->
            </CRow>
          </CCardBody>

          <CCardFooter>
            <CButton color="primary" @click="goBack">Back</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <UserModalsSetNewPassword
      :apiName="`user/${this.$route.params.id}/set_password`"
      :isShow="activeModal == 'setNewPassword'"
      @submit="handlerSubmit"
      @close="activeModal = null"
    />
    <!-- /admin_api/user/{id}/set_password/ -->
    <!-- /admin_api/manage_user_balance/ -->
    <UserModalsAddBalance
      :userId="data.id"
      apiName="manage_user_balance"
      :isShow="activeModal == 'addBalance'"
      @submit="handlerSubmit"
      @close="activeModal = null"
    />

    <UserModalsEditUser
      :data="data"
      apiName="user"
      :isShow="activeModal == 'editUser'"
      :addictionsData="addictionsData"
      @submit="handlerSubmit"
      @close="activeModal = null"
    />
  </div>
</template>

<script>
import { axios, showNotification } from "../../utils/axios";

import UserModalsSetNewPassword from "./modals/UserModalsSetNewPassword";
import UserModalsAddBalance from "./modals/UserModalsAddBalance";
import UserModalsEditUser from "./modals/UserModalsEditUser";

import addictionsMixin from "@/mixins/addictions";

export default {
  name: "User",

  mixins: [addictionsMixin],

  components: {
    UserModalsSetNewPassword,
    UserModalsAddBalance,
    UserModalsEditUser
  },

  data: () => ({
    loading: false,
    activeModal: null,
    data: [],

    fields: {
      id: "Id",
      email: "Email",
      balance: "Balance",
      total_balance: "Deposits",
      loyal_points: "Loyalty",
      country: "Country",
      source: "Source",
      source_url: "Source url",

      verification: "Verification",
      status_text: "Status",
      date_joined: "Created"
      // total_balance: "Total balance",
      // loyal_points: "Loyal balance",
      // status: "Status code",
      // status_text: "Status",
      // is_active: "Active status",
      // source: "Join source",
      // date_joined: "Joined Date"
    }

    // addictions: ["countries"]
  }),

  created() {
    this.loadUser();
  },

  methods: {
    getValue(value, key) {
      if (key == "status") return value ? "Active" : "Banned";
      else if (key == "verification") return value ? "Yes" : "No";
      else if (key == "loyal_points") return `${value}/10`;
      else return value;
    },

    handlerSubmit() {
      this.loadUser();
    },

    handlerVerificated() {
      const url = `/admin_api/user/${this.$route.params.id}/send_verify/`;

      axios
        .post(url)
        .then(response => {
          showNotification({
            type: "success",
            text: "User is verificated"
          });

          this.handlerSubmit();
        })
        .catch(error => {
          console.log(error);

          console.log(error.response.data);
          showNotification({
            type: "danger",
            text: "Error! User is not verificated"
          });
        });
    },

    loadUser() {
      this.loading = true;

      const url = `/admin_api/user/${this.$route.params.id}/`;

      axios
        .get(url)
        .then(response => {
          console.log(response.data);

          this.data = response.data;
          this.loading = true;
        })
        .catch(error => {
          console.log(error);

          this.loading = true;
        });
    },

    goBack() {
      this.$router.go(-1);
    },

    setActiveModal(value) {
      this.activeModal = value;
    }
  }
};
</script>


<style lang="scss" scoped>
.user {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &__title {
      @media (max-width: 575.98px) {
        width: 100%;
      }
    }

    &__button {
      @media (max-width: 575.98px) {
        margin-top: 1rem;
      }
    }
  }

  &__body {
    margin: 0 1.25rem;
  }

  &__row {
    // width: 100%;

    padding: 10px 0;

    display: flex;

    background-color: "red";
    border-bottom: 1px solid #d8dbe0;

    &:last-child {
      border-bottom: none;
    }
  }

  &__label {
    font-weight: 300;
  }

  &__value {
    // font-weight: 500;
  }
}
</style>