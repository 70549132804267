<template>
  <Modal
    :show="isShow"
    title="Set new password"
    :errors="errors"
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <CRow>
      <CCol sm="12">
        <CInput v-model="form.new_password1" label="New password" placeholder="New password"/>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CInput
          v-model="form.new_password2"
          label="Confirm new password"
          placeholder="Confirm new password"
        />
      </CCol>
    </CRow>
  </Modal>
</template>

<script>
import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "UserModalsSetNewPassword",

  mixins: [MixinsScreenModal],

  data: () => ({
    form: {
      new_password1: null,
      new_password2: null
    },

    fetchMethods: "put"
  })
};
</script>


<style lang="scss" scoped>
</style>