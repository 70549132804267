<template>
  <CModal
    :title="title"
    :show="show"
    @update:show="$emit('cansel')"
    @accepted="$emit('accepted')"
    centered
    ok-variant="danger"
  >
    <slot/>

    <ErrorView v-if="errors" :data="errors" class="app-modal-error"/>

    <template slot="footer">
      <div class="app-modal-footer">
        <div class="app-modal-footer__btn">
          <CButton color="secondary" @click="$emit('cansel')">Cancel</CButton>
        </div>

        <div class="app-modal-footer__btn">
          <CButton color="primary" @click="!loading && $emit('ok')">OK</CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>
import ErrorView from "@/components/root/ErrorView";

export default {
  name: "modal",

  props: {
    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    loading: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object,
      default: null
    }
  },

  components: {
    ErrorView
  }
};
</script>

<style lang="scss" scoped>
.app-modal-footer {
  display: flex;
  align-items: center;

  &__btn {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.app-modal-error {
  margin-top: 1rem;
}
</style>