import Vue from "vue";

import { axios, showNotification } from "../utils/axios";

import Modal from "@/components/root/Modal";

import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading);

export default {
  components: {
    Modal
  },

  props: {
    data: {
      type: [Object, Boolean, Array],
      default: null
    },

    isShow: {
      type: Boolean,
      default: false
    },

    apiName: {
      type: String
    },

    addictionsData: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    loading: false,

    errors: null
  }),

  watch: {
    isShow(next) {
      if (next) {
        this.errors = null;

        const newForm = {};

        const formKeys = Object.keys(this.form);

        if (this.data) {
          formKeys.forEach(key => (newForm[key] = this.data[key]));
        } else {
          formKeys.forEach(key => (newForm[key] = null));
        }

        this.form = newForm;
      }
    }
  },

  methods: {
    handlerSubmit() {
      console.log("load");
      if (!this.loading) {
        this.sendForm();
      }
    },

    editDataItem() {
      console.log(this.form);
    },

    sendForm() {
      this.errors = null;

      let url = `/admin_api/${this.apiName}/`;

      const isEdit = !!this.data;

      if (isEdit) url += `${this.data.id}/`;

      let method = null;

      if (this.fetchMethods) method = this.fetchMethods;
      else method = isEdit ? "patch" : "post";

      this.loading = true;

      // let loader = this.$root.$loading.show({
      //   container: this.$refs.formContainer,
      //   canCancel: false
      // });
      // console.log(object)

      let formData = new FormData();

      Object.keys(this.form).forEach(key => {
        const item = this.form[key];

        if (item !== null) {
          formData.append(key, item);
        }
      });

      console.log(this.form);

      axios[method](url, formData)
        .then(response => {
          showNotification({
            type: "success",
            text: isEdit
              ? `Item id ${this.data.id} successfully edited`
              : "Item successfully add"
          });

          this.loading = false;
          // loader.hide();

          this.$emit("close");
          this.$emit("submit");
        })
        .catch(error => {
          this.errors = error.response.data;
          // console.log(error.response.data);

          showNotification({
            type: "danger",
            text: isEdit
              ? `Error! Failed to edit item with id ${this.data.id}`
              : "Error! Failed to add item"
          });

          // loader.hide();
          this.loading = false;
        });
    },

    getOptions(data) {
      return Object.values(data).map(item => item.name);
    }
  }
};
